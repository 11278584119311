import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiCalls from "../api/index";
import "../App.css";


export default function HomePage(props) {
  const [customer, setCustomer] = useState({
    username: "",
    email: "",
    password: "",
    subscription_end_date: "",
  });

 
  

  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username")
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    if (fieldName === "domain")
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    if (fieldName === "password")
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    if (fieldName === "subscription_end_date")
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
  };

  const navigate = useNavigate();

  // login
  const handleSubmit = () => {
    console.log(customer);
    ApiCalls.post(`/api/auth/login`, { username: customer.username, password: customer.password }).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        localStorage.setItem("token", data.token);
       
        if(data.data.is_admin !== 1) {
          localStorage.setItem("is_admin", false);
          getConsumer(data.data.id);
        } else {
          localStorage.setItem("is_admin", true);
          navigate("/admin/dashboard");
        }
        
      }
      else
        alert("Wrong credentials");
    });
    
  };

const getConsumer = (user_id) => {
  ApiCalls.get(`/api/customers/view/${user_id}`).then((res) => {
    if (res.ok) {
      const consumer_id = res.data?.data?.customer?.consumer_id;
    
      if(localStorage.getItem("is_admin"=== true)) {
        navigate("/admin/dashboard");
      }else {
        localStorage.setItem("consumer_id", consumer_id);
        navigate(`/admin/customer/edit/${consumer_id}`);
      }
    }
  });
}

  return (
    <div className="container" style={{marginTop: '30px', paddingTop: '20px'}}>
      <div class="row">
        <div
          class="panel panel-color panel-primary panel-pages"
          style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
        >
          <div class="panel-body">
            <h3 class="text-center m-t-0 m-b-30">
              {" "}
              <span class="">
                <img src="/assets/images/big/bigb.png" alt="logo" style={{height :"auto", maxWidth : "170px"}} />
              </span>
            </h3>
            <h4 class="text-muted text-center m-t-0">
              <b>Sign In</b>
            </h4>
            <form class="form-horizontal m-t-20">
              <div class="form-group">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    style={{fontSize:'15px'}}
                    type="text"
                    required=""
                    placeholder="Username"
                    value={customer.username}
                    onChange={(event) =>
                      handleChangeCustomer(event, "username")
                    }
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <input
                    class="form-control"
                    style={{fontSize:'15px'}}
                    type="password"
                    required=""
                    placeholder="Password"
                    value={customer.password}
                    onChange={(event) =>
                      handleChangeCustomer(event, "password")
                    }
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <div class="checkbox checkbox-primary">
                    <input id="checkbox-signup" type="checkbox" />{" "}
                    <label for="checkbox-signup"> Remember me </label>
                  </div>
                </div>
              </div>
              <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                  {" "}
                  <button type="button"
                    class="btn btn-primary w-md waves-effect waves-light"
                    onClick={handleSubmit}
                  >
                    Log In
                  </button>
                </div>
              </div>
              <div class="form-group m-t-30 m-b-0">
                <div class="col-sm-7"></div>
                <div class="col-sm-5 text-right"> </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}
