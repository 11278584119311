import * as React from "react";
import { useNavigate } from "react-router-dom";
import ApiCalls from "../api/index";
import "../App.css";

export default function HomePage(props) {
  const [ boardName, setBoardName ] = React.useState('');
  const [ apiUrl, setApiUrl ] = React.useState('');

  let navigate = useNavigate();

  const saveBoard = () => {    
    ApiCalls.post(`/api/job-board/add-job-board`, { name: boardName, api_url: apiUrl }).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        console.log(data);
        navigate('/admin/job-boards');
      }
    });
  }


  return (
    <>
    <div class="row">
                            
    <div class="col-md-6">
    <div class="panel panel-primary">
        <div class="panel-heading"><h3 class="panel-title">Add Job Board</h3></div>
        <div class="panel-body">
        <form role="form">
      <div className="form-group">
          <label for="">Name</label>
          <input type="text" class="form-control" value={boardName} 
          onChange={(event) => setBoardName(event.target.value) }  />
      </div>
    <div class="form-group">
      <label for="">API URL</label>
      <input type="text" class="form-control" 
        value={apiUrl} 
        onChange={(event) => setApiUrl(event.target.value) } />
    </div>
  <br />
  <button type="button" onClick={saveBoard} class="btn btn-primary pull-right">Save</button>
  </form>
      </div>
      </div>
    </div>
    </div>
    </>
  );
}
