import * as React from "react";
import { useState } from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [ boards, setBoards ] = React.useState([]);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [customer, setCustomer] = useState({ name:'', username: "", password:'', domain:'', subscription_end_date:'' });

  useEffect(() => {
    ApiCalls.get(`/api/job-board`).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        setBoards(data);
        forceUpdate();
      }
    });
  }, []);

  const handleChangeCustomer = (event, fieldName) => {
    if(fieldName === 'name')
      setCustomer( prevState => { return {...prevState, name:event.target.value} });
      if(fieldName === 'username')
      setCustomer( prevState => { return {...prevState, username:event.target.value} });
    if(fieldName === 'password')
    setCustomer( prevState => { return {...prevState, password:event.target.value} });
    if(fieldName === 'domain')
      setCustomer( prevState => { return {...prevState, domain:event.target.value} });
    // if(fieldName === 'api_access_key')
    //   setCustomer( prevState => { return {...prevState, api_access_key:event.target.value} });
    if(fieldName === 'subscription_end_date')
      setCustomer( prevState => { return {...prevState, subscription_end_date:event.target.value} });
  }

  function handleChangeBoardCheckbox(event, board) {
    setBoards(
        boards.map((_board) => {
            return _board.job_board_id === board.job_board_id ? { ..._board, isSelected: event.target.checked } : _board;
        })
    );
  }
  
  const navigate = useNavigate();

  // save customer and boards access
  const saveCustomer = () => {
    console.log(customer);
    console.log(boards);
    ApiCalls.post(`/api/customers`, { customer:customer, boards: boards.filter(board => board.isSelected) }).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        console.log(data);
        navigate('/admin/customers');
      }
    });
  }

  return (
    <>
    <div class="row">                     
    <div class="col-md-6">
        <div class="panel panel-primary">
            <div class="panel-heading"><h3 class="panel-title">Add Customer</h3></div>
            <div class="panel-body">
                <form role="form">
                    <div class="form-group">
                        <label for="customer_name">Customer Name</label>
                        <input type="text" class="form-control" id="customer_name" 
                        
                        value={customer.name} 
                        onChange={(event) => handleChangeCustomer(event, 'name') }
                        
                        placeholder="Customer Name"/>
                    </div>
                    <div class="form-group">
                        <label for="customer_name">Email Name</label>
                        <input type="email" class="form-control" id="username" 
                        
                        value={customer.username} 
                        onChange={(event) => handleChangeCustomer(event, 'username') }
                        
                        placeholder="Customer Email"/>
                    </div>
                    <div class="form-group">
                        <label for="customer_name">Password</label>
                        <input type="text" class="form-control" id="customer_password" 
                        
                        value={customer.password} 
                        onChange={(event) => handleChangeCustomer(event, 'password') }
                        
                        placeholder="Enter password"/>
                    </div>
                    <div class="form-group">
                        <label for="domain">Domain</label>
                        <input type="text" class="form-control" 
                          id="domain" placeholder="Domain" 
                          value={customer.domain} 
                        onChange={(event) => handleChangeCustomer(event, 'domain') }    />
                    </div>
                    <div class="form-group" style={{display:'none'}}>
                        <label for="api_key">API Access Key</label>
                        <input type="text" class="form-control" 
                          id="api_key" placeholder="API Access Key" 
                          value={customer.api_access_key}  onChange={(event) =>
                          handleChangeCustomer(event, 'api_access_key')
                        } />
                    </div>
                    <div class="form-group">
                        <label for="domain">Subscription End Date</label>
                        <input type="date" class="form-control" 
                          id="domain" placeholder="Subscription End Date"  
                          value={customer.subscription_end_date} 
                        onChange={(event) => handleChangeCustomer(event, 'subscription_end_date') } />
                    </div>
                    <div class="form-group">
                      <br/>
                      {boards.map( board => {
                        return ( 
                            <>
                              <span style={{display:'inline-block', width: "150px"}}>
                                <input type="checkbox" 
                                  onChange={(event) => handleChangeBoardCheckbox(event, board)} /> <span style={{textTransform: 'capitalize'}}>{board.name}</span>
                              </span>
                            </>
                        )
                      })}  
                    </div>
                    <button type="button" onClick={saveCustomer} class="btn btn-info waves-effect waves-light">Save</button>
                </form>
            </div>
        </div> 
    </div> 
  </div>

    </>
  );
}
