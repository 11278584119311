import * as React from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [logs, setLogs] = React.useState([]);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    ApiCalls.get(`/api/job-board`).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        setLogs(data);
        forceUpdate();
      }
    });
  }, []);
  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };
  
  let navigate = useNavigate();
  const goToAddJobBoard = () => {
    navigate("/admin/add-job-board");
  };  

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if ( !localStorage.getItem('token') )
      navigate("/login");
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Job board name",
      width: 250,
    },
    {
        field: "api_url",
        headerName: "API URL",
        width: 500,
    }       
  ];

  const goToCustomerDetailsIdColumn = (consumer_id) => {
    navigate(`/customers/${consumer_id}`);
  };
  
  function getRows(logs) {
    if (logs && logs.length > 0) {
      return logs.map(function (log) {
        return {
          id: log.job_board_id,
          name: log.name,
          api_url: log.api_url          
        };
      });
    } else {
      return [];
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fields: ["id", "name", "timestamp", "status"] }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                <button onClick={goToAddJobBoard} style={{marginTop:'20px', marginBottom:'20px', marginLeft: '15px' }} className="btn btn-primary">Add Job Board</button>
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Job Boards</h3>
                                    </div>
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                            <div class="table-responsive">
                                                      <table id="datatable" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Board name</th>
                                                                <th>Created At</th>
                                                                <th style={{display:'none'}}>Api Url</th>                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {logs.map( log => {
                                                          return ( 
                                                            <>
                                                            <tr >
                                                                
                                                                
                                                                <td style={{textTransform:'capitalize'}}>{ log.name }</td>
                                                                <td>{moment(log.created_at).format("MMM DD, YYYY")}</td>
                                                                <td style={{display:'none'}}>{log.api_url}</td>                                                                
                                                            </tr>
                                                            </>
                                                          )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
    
    <div style={{ height: "auto", marginTop:'25px', display:'none' }} className="container">
      <button onClick={goToAddJobBoard} style={{marginTop:'20px', marginBottom:'20px'}} className="btn btn-primary">Add Job Board</button>
      <DataGrid
        rows={getRows(logs)}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {IsJsonString(xmlData) ? (
            <pre>{returnBeautifyString(xmlData)}</pre>
          ) : (
            <XMLViewer xml={xmlData} />
          )}
        </Box>
      </Modal>
    </div>
    </>
  );
}
