import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from '../helpers/helper';

import ApiCalls from "../api/index";
import "../App.css";



export default function HomePage(props) {
    const [ customerBoards, setCustomerBoards ] = React.useState([]);
    const [ Jobboards, setBoards ] = React.useState([]);
    const [customerEmail, setCustomerEmail ] = useState();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [alert, setAlert] = useState({status: "", message: ""});

  const [customer, setCustomer] = useState({ name:'', domain:'', password: '', confirm_password: "", api_access_key:'', subscription_end_date:'' });
  const params = useParams();
  const userRole = localStorage.getItem("is_admin");
  useEffect(() => {
    getCustomer();
  }, []);
  



  const getCustomer  = async  () => {
    let mounted = true;
    ApiCalls.get(`/api/customers/${params.customerId}`).then((res) => {
      if (mounted) {
        const { customer, boards, user } = res.data.data;
        setCustomer({ 
                    name:customer.name, 
                    domain:customer.domain, 
                    api_access_key:customer.api_access_key, 
                    subscription_end_date: formatDate(customer.subscription_end_date)
                });
        setCustomerEmail(user.username);
        ApiCalls.get(`/api/job-board`).then((res) => {
          if (res.ok) {          
            const output = res.data.data.map(_board => {
              const found =  boards.some(a => a.job_board_id === _board.job_board_id);
                return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
            });
            setBoards(output);
            forceUpdate();
          }
        });
        setCustomerBoards(boards);

      }
    });
    return () => (mounted = false);
  }


  const handleChangeCustomer = (event, fieldName) => {
    if(fieldName === 'name')
      setCustomer( prevState => { return {...prevState, name:event.target.value} });
    if(fieldName === 'password')
      setCustomer( prevState => { return {...prevState, password:event.target.value} });
    if(fieldName === 'confirm_password')
      setCustomer( prevState => { return {...prevState, confirm_password:event.target.value} });
    if(fieldName === 'domain')
      setCustomer( prevState => { return {...prevState, domain:event.target.value} });
    if(fieldName === 'api_access_key')
      setCustomer( prevState => { return {...prevState, api_access_key:event.target.value} });
    if(fieldName === 'subscription_end_date')
      setCustomer( prevState => { return {...prevState, subscription_end_date:event.target.value} });
  }

  function handleChangeBoardCheckbox(event, board) {
    setBoards(
      Jobboards.map((_board) => {
            return _board.job_board_id === board.job_board_id ? { ..._board, isSelected: event.target.checked } : _board;
        })
    );
  }
  
  const navigate = useNavigate();

  // save customer and boards access
  const updateCustomer = () => {
    console.log(customer);
    console.log(Jobboards);
    ApiCalls.post(`/api/customers/${params.customerId}`, { customer:customer, boards: Jobboards.filter(board => board.isSelected) }).then((res) => {
      if (res.ok) {
        setAlert({status: 'success', message: "Saved successfully."});
        const { data } = res.data;
        if((localStorage.getItem('token') && (!localStorage.getItem('consumer_id')))) {
          navigate('/admin/customers');
        }else {
          navigate(`/admin/customers/${params.customerId}`);
          
        }
       
       
      }else {
        setAlert({status: 'error', message: "Error Saving."});
      }
    });
  }



  return (
    <>
    <div class="row">
                            
    <div class="col-md-6">
        <div class="panel panel-primary">
            <div class="panel-heading"><h3 class="panel-title">{(localStorage.getItem('token') && (!localStorage.getItem('consumer_id'))) ? 'Edit Customer ' : 'Customer Profile'}</h3></div>
            <div class="panel-body">

              <div  style={{ display: "none"}}  
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
              </div>
               
                <form role="form">
                    <div class="form-group">
                        <label for="customer_name">Customer Name</label>
                        <input type="text" class="form-control" id="customer_name" 
                        
                        value={customer.name} 
                        onChange={(event) => handleChangeCustomer(event, 'name') }
                        
                        placeholder="Customer Name"/>
                    </div>
                    <div class="form-group">
                        <label for="customer_name">Customer Email</label>
                        <input type="text" class="form-control" id="customer_name" 
                        
                        value={customerEmail} 
                        disabled={true}
                        placeholder="Email"/>
                    </div>
                    <div class="form-group">
                        <label for="domain">Domain</label>
                        <input type="text" class="form-control" 
                            id="domain" placeholder="Domain" 
                            value={customer.domain} 
                            onChange={(event) => handleChangeCustomer(event, 'domain') }    />
                    </div>
                    <div class="form-group">
                        <label for="api_key">API Access Key</label>
                        <input type="text" class="form-control" 
                            id="api_key" placeholder="API Access Key" 
                            disabled={true}
                            value={customer.api_access_key}  onChange={(event) =>
                            handleChangeCustomer(event, 'api_access_key')
                        } />
                    </div>
                    <div class="form-group">
                        <label for="domain">Subscription End Date</label>
                        <input type="date" class="form-control" 
                            style={{ maxWidth: "300px"}}
                            disabled={!localStorage.getItem('consumer_id') ? false : true}
                            id="domain" placeholder="Subscription End Date"  
                            value={customer.subscription_end_date} 
                            onChange={(event) => handleChangeCustomer(event, 'subscription_end_date') } />
                    </div>
                    
                    <div class="form-group">
                        <br/>
                        {Jobboards.map( board => {
                        return ( 
                            <>
                                <span style={{ width: "150px", display:'inline-block'}}>
                                <input type="checkbox"
                                    checked={board.isSelected}
                                    value={board.isSelected} 
                                    onChange={(event) => handleChangeBoardCheckbox(event, board)} /> <span style={{textTransform: 'capitalize'}}>{board?.name}</span>
                                </span>
                            </>
                        )
                        })}  
                    </div>
                    <button type="button" onClick={updateCustomer} class="btn btn-info waves-effect waves-light">Save</button>
                </form>
            </div>
        </div> 
    </div> 
</div>

    </>
  );
}
