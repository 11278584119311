import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../components/header";
import ApiCalls from "../../api/index";
import "../../App.css";


export default function HomePage(props) {

  const [customer, setCustomer] = useState({
    username: "",
    email: "",
    password: "",
    subscription_end_date: "",
  });

  useEffect(() => {

  }, []);


  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username")
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    if (fieldName === "domain")
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    if (fieldName === "password")
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    if (fieldName === "subscription_end_date")
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
  };



  const navigate = useNavigate();

  // sign up
  const handleSubmit = () => {
    // console.log(customer);
    ApiCalls.post(`/api/auth/register`, { username: customer.username, password: customer.password }).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        navigate("/login", { state: "You have been successfully signed up. Please login." });
      }
      else
        alert("something went wrong");
    });
   
  };


  return (
<>
    <Header/>
<div class="coming-soon">
<div className="container" style={{marginTop: '30px', paddingTop: '20px'}}>
<div class="row">
  <div
    class="panel panel-color panel-primary panel-pages"
    style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
  >
    <div class="panel-body">
      <h3 class="text-center m-t-0 m-b-30" style={{display:'none'}}>
        {" "}
        <span class="">
          <img src="assets/images/big/bigb.png" alt="logo" style={{height :"auto", maxWidth : "170px"}} />
        </span>
      </h3>
      <h4 class="text-muted text-center m-t-0">
        <b>Sign Up</b>
      </h4>
      <form class="form-horizontal m-t-20">
        <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control" 
              style={{fontSize:'14px'}}
              type="email"
              required=""
              placeholder="Email"
              value={customer.username}
              onChange={(event) =>
                handleChangeCustomer(event, "username")
              }
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control" 
              style={{fontSize:'14px'}}
              type="password"
              required=""
              placeholder="Password"
              value={customer.password}
              onChange={(event) =>
                handleChangeCustomer(event, "password")
              }
            />
          </div>
        </div>
        <div class="form-group">
                <div className="row inline-form">
                <div class="col-xs-4">
                  <div class="checkbox checkbox-primary" style={{paddingLeft:'30px', fontSize:'14px'}}>
                    <input id="checkbox-signup" type="checkbox" />
                    <label for="checkbox-signup"> Remember me: </label> &nbsp;&nbsp;
                  </div>
                </div>
                <div class="col-xs-2" style={{marginTop: "7px", marginLeft: "290px"}}>
                    <Link to="/login" style={{textDecoration:'underline'}}>Login</Link>
                </div>
                </div>

              </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            {" "}
            <button type="button"
              class="btn btn-primary w-md waves-effect waves-light"
              style={{fontSize:'17px'}}
              onClick={handleSubmit}
              disabled={(customer.username === '') || (customer.password === "")}
            >
              Sign Up
            </button>
          </div>
        </div>
        <div class="form-group m-t-30 m-b-0">
          <div class="col-sm-7"></div>
          <div class="col-sm-5 text-right"> </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>
</>


  );
}
