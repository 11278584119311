import { create } from "apisauce";
let baseURL = process.env.REACT_APP_BASE_URL;


const api = create({
  baseURL,
  // Increase timeout to 10 minutes
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
    "x-consumer": process.env.REACT_APP_NAME,
    "x-api-key": process.env.REACT_APP_SECRET_KEY,
  },
});

export default class ApiCalls {
  static post(url, payload) {
    return api.post(url, payload).then((response) => {
      return response;
    });
  }

  static get(url) {
    return api.get(url).then((response) => {
      return response;
    });
  }
}
