import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiCalls from "../api/index";
import "../App.css";


export default function HomePage(props) {
  const [ customerBoards, setCustomerBoards ] = React.useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const userRole = localStorage.getItem("is_admin");

  const [customer, setCustomer] = useState({consumer_id:"", name:'', domain:'', api_access_key:'', subscription_end_date:''});

  const params = useParams();

  useEffect(() => {
    ApiCalls.get(`/api/customers/${params.customer_id}`).then((res) => {
      if (res.ok) {
        console.log(res.data);
        const { customer, boards } = res.data.data;
        setCustomer(customer);
        setCustomerBoards(boards);
        forceUpdate();
      }
    });
  }, []);

  const handleChangeCustomer = (event, fieldName) => {
    if(fieldName === 'name')
      setCustomer( prevState => { return {...prevState, name:event.target.value} });
    if(fieldName === 'api_access_key')
      setCustomer( prevState => { return {...prevState, api_access_key:event.target.value} });
  }

  

  const navigate = useNavigate();
  const updateCustomerAccess = () => {
    console.log(customerBoards);    
    ApiCalls.post(`/api/customers/update-customer-access`, { boards : customerBoards } ).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        console.log(data);
        navigate('/admin/customers');
      }
    });
  }


  
 

  const handleDeleteBoard = (access_id) => {
    if (window.confirm("Are you sure want to delete?")){    
      //Logic to delete the job
      ApiCalls.post(`/api/customers/delete-customer-access/${access_id}`).then((res) => {        
        if (res.ok) {
          const { data } = res.data;                    
         setCustomerBoards(
          customerBoards.filter( customerBoard => customerBoard.access_id !== access_id)
          );         
        }
      });
    }
    // setCustomerBoards(
    //   customerBoards.filter((_board, _index) => _index !== index)
    // );
  }



  function handleChangeBoardData(event, fieldName, board) {
    
    if (fieldName === 'username'){
      setCustomerBoards(
          customerBoards.map((_board) => {
              return _board.job_board_id === board.job_board_id ? { ..._board, username: event.target.value } : _board;
          })
      );
    }

    if (fieldName === 'password'){
      setCustomerBoards(
          customerBoards.map((_board) => {
              return _board.job_board_id === board.job_board_id ? { ..._board, password: event.target.value } : _board;
          })
      );
    }

    if (fieldName === 'api_key'){
      setCustomerBoards(
          customerBoards.map((_board) => {
              return _board.job_board_id === board.job_board_id ? { ..._board, api_key: event.target.value } : _board;
          })
      );
    }
    ApiCalls.post(`/api/customers/update-customer-access`, { boards : customerBoards } ).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        console.log(data);
      }
    });
  }

  const editCustomerDetail = () => {
    navigate(`/admin/customer/edit/${localStorage.getItem("consumer_id")}`);
  }
  return (
    <div className="container">
      {/* {userRole &&
        <button  onClick={editCustomerDetail} style={{marginBottom:'20px' }} className="btn btn-primary">Update Customer</button>
      } */}
        
        <div class="row">
                            
      <div class="col-md-10">
          <div class="panel panel-primary">
              <div class="panel-heading"><h3 style={{textTransform:'capitalize'}} class="panel-title">{ customer.name }</h3></div>
              <small style={{marginLeft:"20px", marginTop:"10px", display: "inline-block", fontSize:"16px"}}>
                API Documentation</small>:&nbsp;&nbsp;
                <p style={{display:"inline", fontSize:"16px"}}>
                  <a href="https://beanbag-dsaas.simplifysandbox.net/api-docs/" target="_blank">Link</a>
                </p>

              <div class="panel-body">
              <form role="form">        
        
              <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-xs-6" for="domain">Domain</label>
                      <input readOnly class="form-control" id="domain" type="text" value={customer.domain} />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="col-xs-6">API Access Key</label>
                        <input readOnly class="form-control" type="text" value={customer.api_access_key}  onChange={(event) =>
                        handleChangeCustomer(event, 'api_access_key')} />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-xs-6" for="domain">Customer ID</label>
                      <input readOnly class="form-control" id="domain" type="text" value={customer.consumer_id} />
                    </div>
                  </div>
                </div>
              <div class="col-xs-12 form-group" style={{marginTop:'10px', paddingLeft:'0px', paddingRight:'0px'}}>
              <table id="datatable" class="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th>Job Board</th>
                          <th>Email</th>
                          <th>Password</th>
                          <th>API Key</th>
                          <th class="text-center">Action</th>                                                                                                                                
                      </tr>
                  </thead>
                  <tbody>
                  {customerBoards.map( (board, index) => {
                    console.log("board", board);
                    return ( 
                        <>
                          <tr>
                            <td style={{textTransform:'capitalize', color:'black'}}>{board.name}</td>
                              <td>
                                  <input type="email" class="form-control" id="inputEmail3" value={board.username} 
                                  onChange={(event) => handleChangeBoardData(event, 'username', board) } placeholder="" />
                                  </td>
                                <td>
                                <input type="password" class="form-control" id="inputPassword3" value={board.password} 
                                  onChange={(event) => handleChangeBoardData(event, 'password', board) } placeholder=""/>
                                </td>
                                  <td>
                                    <input type="text" class="form-control" id="inputPassword4" value={board.api_key} 
                                      onChange={(event) => handleChangeBoardData(event, 'api_key', board) } placeholder=""/>
                                  </td>
                                    <td class="text-center">
                                      <a  style={{ cursor: 'pointer'}} href={`https://beanbag-dsaas.simplifysandbox.net/api/jobs/${board.consumer_id}/${board.name}/xml`} target="_blank" title="XML">xml</a>&nbsp;
                                      <a  style={{ cursor: 'pointer'}} onClick={() => handleDeleteBoard(board.access_id)} title="Delete"><i class="ti-trash"></i></a>
                                      </td>
                              
                              <span style={{marginRight:'50px', marginBottom:'10px', display:'none'}}>
                                <h5><span style={{textTransform: 'capitalize'}}></span></h5>
                                <div class="row">
                                  <div class="form-group">
                                    <label>Username / Email</label>
                                    <input type="text" 
                                      className='form-control' 
                                      value={board.username} 
                                      onChange={(event) => handleChangeBoardData(event, 'username', board) } />
                                  </div>
                                  <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" 
                                    value={board.password} 
                                    onChange={(event) => handleChangeBoardData(event, 'password', board) } />
                                  </div>
                                </div>
                                <div class="row" style={{marginTop:'10px'}}>
                                  <div class="col-xs-12 col-sm-12">
                                    <label>API Key / Company Id / Client Id</label>
                                    <input type="text" className="form-control" 
                                    value={board.api_key} 
                                    onChange={(event) => handleChangeBoardData(event, 'api_key', board) }
                                      />            
                                  </div>
                                </div>
                                
                              </span>
                          </tr>
                          
                        </>                  
                    )
                  })}  
                  </tbody>
                </table>
                  <button onClick={updateCustomerAccess} style={{marginTop:'13px', display:"none"}} type="button" class="btn btn-primary pull-right">Save</button>
              </div>
          </form>
          </div>
          </div>
          </div>
    </div>    
    </div>
  );
}
