import * as React from "react";
import { useState } from 'react';
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import ReactPaginate from 'react-paginate';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function JobsPage(props) {
  const [logs, setLogs] = React.useState([]);
  const [ boards, setBoards ] = useState([]);
  const [ customers, setCustomers ] = useState([]);
  const [remountComponent, setRemountComponent] = useState(0);
  const [ paginationData, setPaginationData ] = useState({});
  const [ currentPage, setCurrentPage ] = useState(1);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleteLoading, setIsDeleteLoading ] = useState(false);
  const [ deletingId, setDeletingId ] = useState(null);
  const [color, setColor] = useState("#03a9f4");
  const [ jobTitle, setJobTitle ] = useState('');
  const [ status, setStatus ] = useState('');
  const [ board, setBoard ] = useState('');
  const [ customer, setCustomer ] = useState('');
  const [, updateState] = React.useState();
  const customerId = localStorage.getItem('consumer_id');   

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #03a9f4;
`;

  useEffect(() => {
    searchJobs();
  }, []);

  const itemsPerPage = 10;

  const searchJobs = (page=1) => {
    setIsLoading(true); 
    ApiCalls.get(`/api/logs?jobTitle=${jobTitle}&board=${board}&customer=${customerId ? customerId : customer}&status=${status}&page=${page}`).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        setLogs(data.logs.data);
        setBoards(data.boards);
        setCustomers(data.customers);
        if ( page === 1 ){
          setPaginationData(data.logs.pagination);        
          setPageCount(Math.ceil( data.logs.pagination.total / itemsPerPage));
        }
        setIsLoading(false);
        //forceUpdate();
      }
    });
  }

  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };

  const handleDeleteJob = (job_id) => {
    if (window.confirm("Are you sure want to delete?")){    
      //Logic to delete the job
      setDeletingId(job_id);
      setIsDeleteLoading(true);
      ApiCalls.post(`/api/logs/delete-job/${job_id}`).then((res) => {        
        if (res.ok) {
          const { data } = res.data;                    
          setIsDeleteLoading(false);          
          setLogs(logs.filter( job => job.id !== job_id));
          //searchJobs(1);          
        }
      });
    }
  }  

  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % paginationData.total;
    setCurrentPage(event.selected+1);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    searchJobs(event.selected+1);
  };


  const showModalWithResponseXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.responsePayload)}
        >
          View
        </a>
      </strong>
    );
  };
  const showModalWithRequestXML = (params) => {
    return (
      <strong>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleModalOpen(params.row.requestPayload)}
        >
          View
        </a>
      </strong>
    );
  };

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const handleClose = () => setOpen(false);
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Job board",
      width: 150,
    },
    {
      field: "timestamp",
      headerName: "Posted AT",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "requestPayload",
      headerName: "Request Payload",
      renderCell: showModalWithRequestXML,
      width: 150,
      sortable: false,
    },
    {
      field: "responsePayload",
      headerName: "Response Payload",
      renderCell: showModalWithResponseXML,
      width: 150,
      sortable: false,
    },
  ];

  function getStatusText(status) {
    if (status === "C") {
      return "Completed";
    } else if (status === "R") {
      return "Rejected";
    } else if (status === "P") {
      return "Pending";
    }
  }
  function getRows(logs) {
    if (logs && logs.length > 0) {
      return logs.map(function (log) {
        return {
          id: log.log_id,
          name: log.name,
          status: getStatusText(log.status),
          timestamp: moment(log.request_timestamp).format(
            "DD-MMM-YYYY HH:mm:ss"
          ),
          requestPayload: log.request_payload,
          responsePayload: log.response_payload,
        };
      });
    } else {
      return [];
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fields: ["id", "name", "timestamp", "status"] }}
        />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Jobs Posted</h3>
                                    </div>
                                    <div class="panel-body" style={{paddingBottom:'5px'}}>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <label>Job Title</label>
                                            <input type="text" 
                                            value={jobTitle} 
                                            onChange={ (e) => setJobTitle(e.target.value) }
                                            class="form-control" placeholder="" />
                                          </div>
                                          <div class="col-md-9">
                                            <div class="row">

                                            
                                          <div class="col-md-2">
                                            <label>Job Board</label>
                                            <select onChange={ (e) => setBoard(e.target.value) } class="form-control" placeholder="Select">
                                              <option value=''>Select</option>
                                              {boards.map( board => {
                                                return ( 
                                                    <option value={board.job_board_id} style={{textTransform:'capitalize'}}>{board.name}</option>
                                                )
                                              })}
                                            </select>
                                          </div>
                                          {!customerId && 
                                          <div class="col-md-2">
                                            <label>Customer</label>
                                            <select onChange={ (e) => setCustomer(e.target.value) } class="form-control">
                                              <option value=''>Select</option>
                                              {customers.map( customer => {
                                                return ( 
                                                    <option value={customer.consumer_id} style={{textTransform:'capitalize'}}>{customer.name}</option>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        }
                                          <div class="col-md-2">
                                            <label>Status</label>
                                            <select onChange={ (e) => setStatus(e.target.value)   } class="form-control">
                                              <option value=''>Select</option>
                                              <option value='P'>Pending</option>
                                              <option value='R'>Rejected</option>
                                              <option value='C'>Completed</option>
                                            </select>
                                          </div>
                                          <div class="col-md-2">
                                            <label>From Date</label>
                                            <input type="text" class="form-control" placeholder="" id="datepicker-autoclose" />
                                          </div>
                                          <div class="col-md-2">
                                            <label>To Date</label>
                                            <input type="text" class="form-control" placeholder="" id="datepicker-autoclose2" />
                                          </div>
                                          <div class="col-md-2">
                                            
                                            <button type="button" onClick={ () => { setItemOffset(0); setPaginationData(null); setPageCount(0); searchJobs(1);  setRemountComponent(Math.random()); } } style={{marginTop:'27px', width: '100%'}} class="btn btn-primary">Search</button>
                                          </div>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                    <div class="panel-body">
                                    <BarLoader color={color} loading={isLoading} css={override} size={550} />
                                      { !isLoading && 
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="">
                                                <table id="datatable" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Job Title</th>
                                                                <th>Board Name</th>
                                                                <th>Customer Name</th>                                                                
                                                                <th class="text-center">Posted Date</th>
                                                                <th class="text-center">Status</th>
                                                                <th class="text-center">Request Payload</th>
                                                                <th class="text-center">Response Payload</th>
                                                                <th class="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                          { logs.length < 1 && 
                                                            <tr>
                                                                <td colSpan={7} className='text-center'>No Jobs found.</td>
                                                            </tr>
                                                          }
                                                        {logs.map( log => {
                                                          return ( 
                                                            <>
                                                            <tr>
                                                                <td>{log.title}</td>
                                                                <td style={{textTransform:'capitalize'}}>{log.board_name}</td>                                                                
                                                                <td style={{textTransform:'capitalize'}}>{log.customer_name}</td>
                                                                <td class="text-center">{moment(log.request_timestamp).format("MMM DD, YYYY")}</td>
                                                                <td class="text-center" style={{fontSize:'16px'}}><span 
                                                                className={`label ${
                                                                  log.status === 'C'?'label-success':log.status === 'R'?'text-danger':'label-dark'
                                                                }`}
                                                                >{ getStatusText(log.status)}</span></td>                                                                
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}><a onClick={() => handleModalOpen(log.request_payload)} title="View"><i class="ti-eye"></i></a></td>
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}><a onClick={() => handleModalOpen(log.response_payload)} title="View"><i class="ti-eye"></i></a></td>
                                                                <td class="text-center" style={{width:'150px', cursor: 'pointer'}}><a onClick={() => handleDeleteJob(log.id)} title="Delete"><i class="ti-trash"></i> {isDeleteLoading && deletingId===log.id?'Deleting...':''}</a></td>
                                                            </tr>
                                                            </>
                                                          )
                                                        })}
                                                        </tbody>
                                                    </table>                                                    
                                                </div>
                                            </div>
                                        </div>
                                      }
                                        <div key={remountComponent}>
                                          <ReactPaginate
                                            className="pagination pull-right"
                                              breakLabel="..."
                                              nextLabel="&raquo;"
                                              onPageChange={handlePageClick}
                                              pageRangeDisplayed={5}
                                              pageCount={pageCount}
                                              previousLabel="&laquo;"
                                              activeClassName="active"
                                              renderOnZeroPageCount={null}
                                                          />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
    
    <div className="table-responsive" style={{ height: "auto", display:'none' }}>      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {IsJsonString(xmlData) ? (
            <pre>{returnBeautifyString(xmlData)}</pre>
          ) : (
            <XMLViewer xml={xmlData} />
          )}
        </Box>
      </Modal>
    </div>
    </>
  );
}
