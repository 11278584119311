import * as React from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "600px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [customers, setCustomers] = React.useState([]); 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    ApiCalls.get(`/api/customers`).then((res) => {
      if (res.ok) {
        const { data } = res.data;
        setCustomers(data);
        forceUpdate();
      }
    });
  }, []);

  useEffect(() => {
    if ( !localStorage.getItem('token') )
      navigate("/login");
  }, []);    

  const goToCustomerDetailsIdColumn = (consumer_id) => {
    navigate(`/admin/customers/${consumer_id}`);
  };  

  const editCustomerDetail = (consumer_id) => {
    navigate(`/admin/customer/edit/${consumer_id}`);
  }
  const handleDeleteCustomer = (consumer_id) => {
    if (window.confirm("Are you sure want to delete?")){    
      //Logic to delete the job
      ApiCalls.post(`/api/customers/delete-customer/${consumer_id}`).then((res) => {        
        if (res.ok) {
          const { data } = res.data;                    
         setCustomers(
          customers.filter( customer => customer.consumer_id !== consumer_id)
          );         
        }
      });
    }
    // setCustomerBoards(
    //   customerBoards.filter((_board, _index) => _index !== index)
    // );
  }
  let navigate = useNavigate();
  const goToAddCustomer = () => {
    navigate("/admin/add-customer");
  };

  function getStatusText(status) {
    if (status === "A") {
      return "Active";
    } else if (status === "I") {
      return "Inactive";
    } else if (status === "P") {
      return "Pending";
    }
  }

  
  return (
    <div>
    <div class="row">
                            <div class="col-md-12">
                                <div class="panel panel-primary">
                                <button onClick={goToAddCustomer} style={{marginTop:'20px', marginBottom:'20px', marginLeft: '15px' }} className="btn btn-primary">Add Customer</button>
                                    <div class="panel-heading">
                                        <h3 class="panel-title">Customers</h3>
                                    </div>
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="table-responsive">
                                                      <table id="datatable" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Customer ID</th>                                                                
                                                                <th>Status</th>
                                                                <th>Subscription End Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {customers.map( customer => {
                                                          return ( 
                                                            <>
                                                            <tr style={{cursor: 'pointer'}}>
                                                            <td style={{textTransform:'capitalize'}}>{ customer.name }</td>
                                                                <td  onClick={ () => goToCustomerDetailsIdColumn(customer.consumer_id) }>{ customer.consumer_id }</td>                                                                
                                                                <td><span  className={`label ${
                                                                  customer.status === 'A' ?'label-success' :'label-dark'
                                                                }`}>{ getStatusText(customer.status)}</span></td>                                          
                                                                <td>{moment(customer.subscription_end_date).format("MMM DD, YYYY")}</td>        
                                                                <td>
                                                                  <a onClick={ () => goToCustomerDetailsIdColumn(customer.consumer_id) } title="View"><i class="ti-eye"></i></a> 
                                                                  <a onClick={ () => editCustomerDetail(customer.consumer_id) } style={{marginLeft:"10px"}} title="Edit"><i class="ti-pencil"></i></a> 
                                                                  <a  style={{ marginLeft:"10px"}} onClick={() => handleDeleteCustomer(customer.consumer_id)} title="Delete"><i class="ti-trash"></i></a>
                                                                </td>                                                        
                                                            </tr>
                                                            </>
                                                          )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

    
    </div>
  );
}
