import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

  useEffect(() => {
    document.querySelector('body').classList.remove('frontend');

  }, []);


  let navigate = useNavigate();

  const goToLogs = (e) => {
    e.preventDefault();
    navigate("/admin/jobs");
  };

  const goToDashboard = (e) => {
    e.preventDefault();
    navigate("/admin/dashboard");
  };

  const goToJobBoards = (e) => {
    e.preventDefault();
    navigate("/admin/job-boards");
  };

  const goToCustomers = (e) => {
    e.preventDefault();
    navigate("/admin/customers");
  };

  const goToProfile = (e) => {
    e.preventDefault();
    navigate(`/admin/customer/edit/${localStorage.getItem("consumer_id")}`);
  };
  const goToJobCustomerBoards = (e) => {
    navigate(`/admin/customers/${localStorage.getItem("consumer_id")}`);
  }
  const changePassword = (e) => {
    e.preventDefault();
    navigate("/admin/change-password");
  }
  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/login";
  }

  useEffect(() => {
    if ( !localStorage.getItem('token') )
      navigate("/login");
  }, []);

  return (
    <>
     
     <div class="topbar">
                
                <div class="topbar-left">
                    <div class="text-center">
                        <a href="/" class="logo"><img src="/temp-assets/images/logo/white-logo.png" 
                        style={{height:'43px'}}
                        /></a>
                        <a href="/" class="logo-sm"><img src="/assets/images/logo_white_2.png" height="36"/></a>
                    </div>
                </div>
                
                <div class="navbar navbar-default" role="navigation">
                    <div class="container" style={{background: "#002962"}}>
                        <div class="">
                            <div class="pull-left">
                                <button onClick={ () => document.getElementById('wrapper').classList.toggle('enlarged') } type="button" class="button-menu-mobile open-left waves-effect waves-light">
                                    <i class="ion-navicon"></i>
                                </button>
                                <span class="clearfix"></span>
                            </div>                            

                            <ul class="nav navbar-nav navbar-right pull-right">                                
                                <li class="hidden-xs" style={{display:'none'}}>
                                    <a href="#" id="btn-fullscreen" class="waves-effect waves-light"><i class="fa fa-crosshairs"></i></a>
                                </li>
                                <li class="dropdown" style={{display: 'none'}}>
                                    <a href="" class="dropdown-toggle profile waves-effect waves-light" data-toggle="dropdown" aria-expanded="true"><img src="assets/images/users/avatar-1.jpg" alt="user-img" class="img-circle"/> </a>
                                    <ul class="dropdown-menu">
                                        <li><a href="javascript:void(0)"> Profile</a></li>
                                        <li><a href="javascript:void(0)"><span class="badge badge-success pull-right">5</span> Settings </a></li>
                                        <li><a href="javascript:void(0)"> Lock screen</a></li>
                                        <li class="divider"></li>
                                        <li><a href="javascript:void(0)"> Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>

    
    <div class="left side-menu">
                <div class="sidebar-inner slimscrollleft">                    
                {(localStorage.getItem('token') && (!localStorage.getItem('consumer_id'))) && 
                    <div id="sidebar-menu">
                        <ul>
                            <li>
                              {window.location.href.split('/')[1]}
                                <a onClick={goToDashboard} 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""                                  
                                }`}
                                ><i class="ti-home"></i><span> Dashboard </span></a>
                            </li>                            

       
                            <li class="has_sub">
                                <a onClick={goToLogs} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "jobs" ? "active" : ""
                                }`}><i class="ti-server"></i> <span> Jobs Posted </span> <span class="pull-right"></span></a>                                
                            </li>

                            <li class="has_sub">
                                <a onClick={goToJobBoards} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "job-boards" ? "active" : ""
                                }`}><i class="ti-clipboard"></i> <span> Job Boards </span> <span class="pull-right"></span></a>                                
                            </li>                            

                            <li class="has_sub">
                                <a onClick={goToCustomers} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "customers" ? "active" : ""
                                }`}
                                ><i class="ti-user"></i><span> Customers </span><span class="pull-right"></span></a>                                
                            </li>

                            <li class="has_sub">
                                <a onClick={changePassword} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "change-password" ? "active" : ""
                                }`}
                                ><i class="ti-user"></i><span> Change password </span><span class="pull-right"></span></a>                                
                            </li>


                            <li class="has_sub">
                                <a onClick={logout} href="javascript:void(0);"                                  
                                ><i class="ti-arrow-left"></i><span> Logout </span><span class="pull-right"></span></a>                                
                            </li>                                                       
                                                        
                        </ul>
                    </div>
                    }
                    <div class="clearfix"></div>
                  
                </div> 
            </div>
   
    {(localStorage.getItem('token') && (localStorage.getItem('consumer_id'))) && 
    <div class="left side-menu">
                <div class="sidebar-inner slimscrollleft">                    
                    
                    <div id="sidebar-menu">
                        <ul>
                            <li>
                              {window.location.href.split('/')[1]}
                                <a onClick={goToDashboard} 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""                                  
                                }`}
                                ><i class="ti-home"></i><span> Dashboard </span></a>
                            </li>                            
                            <li class="has_sub">
                                <a onClick={goToLogs} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "jobs" ? "active" : ""
                                }`}><i class="ti-server"></i> <span> Jobs Posted </span> <span class="pull-right"></span></a>                                
                            </li>
                            <li class="has_sub">
                                <a onClick={goToJobCustomerBoards} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "customers" ? "active" : ""
                                }`}><i class="ti-clipboard"></i> <span> Job Boards </span> <span class="pull-right"></span></a>                                
                            </li>  
                            <li class="has_sub">
                            <a onClick={goToProfile} href="" 
                              className={`waves-effect ${
                                window.location.pathname.split("/")[2] === "customer" ? "active" : ""
                              }`}><i class="ti-clipboard"></i><span> Profile </span><span class="pull-right"></span></a>                                
                            </li>
                            <li class="has_sub">
                                <a onClick={changePassword} href="javascript:void(0);" 
                                 className={`waves-effect ${
                                  window.location.pathname.split("/")[2] === "change-password" ? "active" : ""
                                }`}
                                ><i class="ti-user"></i><span> Change password </span><span class="pull-right"></span></a>                                
                            </li>
                             
 


                            <li class="has_sub">
                                <a onClick={logout} href="javascript:void(0);"                                  
                                ><i class="ti-arrow-left"></i><span> Logout </span><span class="pull-right"></span></a>                                
                            </li>                                                       
                                                        
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div> 
            </div>
    }        
            
</>
  );
}

// export default Navbar;
