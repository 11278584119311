import React, { Fragment } from "react";
import PostJob from "../pages/PostJob";
import JobPage from "../pages/JobPage";
import { BrowserRouter as Router, useRoutes, Route, Routes } from "react-router-dom";
import JobsPage from "../pages/JobsPage";
import DashboardPage from "../pages/DashboardPage";
import JobBoardsList from "../pages/JobBoardsList";
import AddJobBoard from "../pages/AddJobBoard";
import CustomersList from "../pages/CustomersList";
import AddCustomer from "../pages/AddCustomer";
import EditCustomer from "../pages/EditCustomer";
import Login from "../pages/Login";
import CutomerLogin from '../pages/customerAuth/Login';
import RegisterRegister from "../pages/customerAuth/Register";
import CustomerDetails from "../pages/CustomerDetails";
import MainAdmin from '../containers/layouts/admin';
import Home  from '../pages/public/Home';
import ChangePassword  from '../pages/ChangePassword';
const AppWrapper = () => {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/admin/login" element={<Login/>}/>
          <Route exact path="/login" element={<CutomerLogin/>}/>
          <Route exact path="/register" element={<RegisterRegister/>}/>
          <Route path="/admin" element={<MainAdmin />}>
            <Route path='dashboard' exact element={<DashboardPage />} />
            <Route path='jobs' element={< JobsPage />} />
            <Route path='customers' element={<CustomersList />} />
            <Route path='add-customer' element={< AddCustomer />} />
            <Route path='customer/edit/:customerId' element={< EditCustomer />} />
            <Route path='customer/edit/:customerId' element={< EditCustomer />} />
            <Route path='customer' element={<CustomersList />} />
            <Route path='job-boards' element={< JobBoardsList />} />
            <Route path='add-job-board' element={< AddJobBoard />} />
            <Route path='customers/:customer_id' element={< CustomerDetails />} />
            <Route path='change-password' element={< ChangePassword />} />
        </Route>          
        </Routes>
      </Router>
  );
};

class RootRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return <AppWrapper />;
  }
}

export default RootRoutes;
