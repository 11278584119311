import React from 'react'
import Header from '../../components/header'
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>


        <Header/>


        <section class="banner-section style-6">
			<div class="banner-area">
				<div class="container">
					<div class="row no-gutters align-items-center">
						<div class="col-lg-6 col-12">
							<div class="content-part">
								<div class="section-header style-2">
									<h2>Post Jobs like a Pro</h2>
                                    <p>Simultaneously post job adverts to job boards, search engines and social media channels whilst tracking the performance of every channel.</p>
                                    <Link to="/register" style={{fontSize:'16px', padding:'16px 25px'}} class="btn btn-primary w-md waves-effect waves-light"><span>Request a Demo</span></Link>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="section-wrapper">
								<div class="banner-thumb">
                                    <img src="temp-assets/images/01.png" alt="marketing-banner" />
                                    <div class="thumb-shape">
										<div class="th-shape th-shape-1"><img src="temp-assets/images/banner/marketing/shape/02.png" alt="th-shape" /></div>
										<div class="th-shape th-shape-2"><img src="temp-assets/images/banner/marketing/shape/04.png" alt="th-shape" /></div>
										<div class="th-shape th-shape-3"><img src="temp-assets/images/banner/marketing/shape/03.png" alt="th-shape" /></div>
										<div class="th-shape th-shape-4"><img src="temp-assets/images/banner/marketing/shape/01.png" alt="th-shape" /></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        
        
		<section class="about-section style-2 style-3 style-4 padding-tb">
            <div class="container">
                <div class="section-header">
                    <span>what we do</span>
                    <h2>We Bring Creative Solutions To Our Clients Both In Marketing And Pptimization.</h2>
                </div>
                <div class="section-wrapper">
                    <div class="lab-item">
                        <div class="lab-inner">
                            <div class="lab-thumb">
                                <img src="temp-assets/images/about/marketing/01.png" alt="lab-thumb" />
                            </div>
                            <div class="lab-content">
                                <h4>Research And Analysis</h4>
                                <p>Hunky dory barney fanny around up  the duff not bigge loo cup info tea joly good create ruddy say arse.!</p>
                            </div>
                        </div>
                    </div>
                    <div class="lab-item">
                        <div class="lab-inner">
                            <div class="lab-thumb">
                                <img src="temp-assets/images/about/marketing/02.png" alt="lab-thumb" />
                            </div>
                            <div class="lab-content">
                                <h4>Marketing Strategy</h4>
                                <p>Hunky dory barney fanny around up  the duff not bigge loo cup info tea joly good create ruddy say arse.!</p>
                            </div>
                        </div>
                    </div>
                    <div class="lab-item">
                        <div class="lab-inner">
                            <div class="lab-thumb">
                                <img src="temp-assets/images/about/marketing/03.png" alt="lab-thumb" />
                            </div>
                            <div class="lab-content">
                                <h4>Growth Business</h4>
                                <p>Hunky dory barney fanny around up  the duff not bigge loo cup info tea joly good create ruddy say arse.!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="market-range-section padding-tb">
            <div class="container">
                <div class="top-area">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="mr-thumb">
                                <img src="temp-assets/images/marketing/01.png" alt="mr-thumb" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="section-header style-2">
                                <span>WHY CHOOSE US?</span>
                                <h2>We Help You Boost Rour Conversion Rate</h2>
                                <p>Don’t get shirty with mein owtto doing with me arse down the problems have t car boot happy days no biggie bevvy only awesome quid I,m goods info my I,m goods info my awesome theme flat the little rotter cheeky.!</p>
                            </div>
                            <div class="section-wrapper">
                                <div class="skill-bar-wrapper">
                                    <div class="skill-item">
                                        <div class="skill-title">
                                            <div class="left">New Customers</div>
                                            <div class="right">85%</div>
                                        </div>
                                        <div class="skillbar-container clearfix" data-percent="85%">
                                            <div class="skills" style={{background: "linear-gradient(to top, #23cc88, #8ecf35)"}}></div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <div class="skill-title">
                                            <div class="left">Website Traffic</div>
                                            <div class="right">70%</div>
                                        </div>
                                        <div class="skillbar-container clearfix" data-percent="70%">
                                            <div class="skills" style={{background: "linear-gradient(to top, #ff4f58, #ffb400)"}}></div>
                                        </div>
                                    </div>
                                    <div class="skill-item">
                                        <div class="skill-title">
                                            <div class="left">Mobile Visits</div>
                                            <div class="right">60%</div>
                                        </div>
                                        <div class="skillbar-container clearfix" data-percent="60%">
                                            <div class="skills" style={{background: "linear-gradient(to top, #01cbad, #47a1f2)"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-area">
                    <div class="section-wrapper">
                        <div class="contact-count-item">
                            <div class="contact-count-inner">
                                <div class="contact-count-content">
                                    <h5 style={{color: "#fb4f6b"}}><span class="counter">370</span>+</h5>
                                    <p>Completed Projects</p>
                                </div>
                            </div>
                        </div>
                        <div class="contact-count-item">
                            <div class="contact-count-inner">
                                <div class="contact-count-content">
                                    <h5 style={{color: "#ffb400"}}><span class="counter">348</span></h5>
                                    <p>Award Winner</p>
                                </div>
                            </div>
                        </div>
                        <div class="contact-count-item">
                            <div class="contact-count-inner">
                                <div class="contact-count-content">
                                    <h5 style={{color: "#c961fa"}}><span class="counter">25</span>+</h5>
                                    <p>Years of Market</p>
                                </div>
                            </div>
                        </div>
                        <div class="contact-count-item">
                            <div class="contact-count-inner">
                                <div class="contact-count-content">
                                    <h5 style={{color: "#38cd78"}}><span class="counter">24</span>K +</h5>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="service-pricing padding-tb">
            <section class="service-section style-2 style-6">
                <div class="container">
                    <div class="section-header">
                        <span>what we do</span>
                        <h2>All Kind of Marketing Solutions For You</h2>
                    </div>
                    <div class="section-wrapper">
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/01.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>Digital Marketing</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/02.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>Marketing Analysis</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/03.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>Email Marketing</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/04.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>SEO Optimization</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/05.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>Social Media Marketing</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                        <div class="lab-item">
                            <div class="lab-inner">
                                <div class="lab-thumb">
                                    <img src="temp-assets/images/service/marketing/06.png" alt="lab-thumb" />
                                </div>
                                <div class="lab-content">
                                    <h4>Business Growth</h4>
                                    <p>Hunky dory barney fanny around up the duff no bigge loo cup of tea joly good ruddy say arse.!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pricing-table marketing padding-tb pb-0">
                <div class="container">
                    <div class="section-header">
                        <span>Pricing tabile</span>
                        <h2>Choose Your Best Pricing Plan</h2>
                    </div>
                    <div class="section-wrapper">
                        <div class="pricing-item">
                            <div class="pricing-inner">
                                <div class="pricing-head">
                                    <h4>Basic Plan</h4>
                                    <p>Holisticly initiate functonzed information without viral imperatives.</p>
                                </div>
                                <div class="pricing-body">
                                    <div class="price">
                                        <h2>$4.99 </h2>
                                        <p>Per-Month</p>
                                    </div>
                                    <div class="price-list">
                                        <ul>
                                            <li>All Bronze Features</li>
                                            <li>Unlimited Websites</li>
                                            <li>Unlimited Bandwidth</li>
                                            <li>Unlimited Storage</li>
                                            <li>2 Month SSL</li>
                                        </ul>
                                    </div>
                                    <div class="price-btn">
                                        <a href="#" class="lab-btn"><span>Start Free Trial</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pricing-item">
                            <div class="pricing-inner">
                                <div class="pricing-head">
                                    <h4>Sliver Plan</h4>
                                    <p>Holisticly initiate functonzed information without viral imperatives.</p>
                                </div>
                                <div class="pricing-body">
                                    <div class="price">
                                        <h2>$3.99 </h2>
                                        <p>Per-Month</p>
                                    </div>
                                    <div class="price-list">
                                        <ul>
                                            <li>All Bronze Features</li>
                                            <li>Unlimited Websites</li>
                                            <li>Unlimited Bandwidth</li>
                                            <li>Unlimited Storage</li>
                                            <li>2 Month SSL</li>
                                        </ul>
                                    </div>
                                    <div class="price-btn">
                                        <a href="#" class="lab-btn"><span>Start Free Trial</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pricing-item">
                            <div class="pricing-inner">
                                <div class="pricing-head">
                                    <h4>Gold Plan</h4>
                                    <p>Holisticly initiate functonzed information without viral imperatives.</p>
                                </div>
                                <div class="pricing-body">
                                    <div class="price">
                                        <h2>$2.99 </h2>
                                        <p>Per-Month</p>
                                    </div>
                                    <div class="price-list">
                                        <ul>
                                            <li>All Bronze Features</li>
                                            <li>Unlimited Websites</li>
                                            <li>Unlimited Bandwidth</li>
                                            <li>Unlimited Storage</li>
                                            <li>2 Month SSL</li>
                                        </ul>
                                    </div>
                                    <div class="price-btn">
                                        <a href="#" class="lab-btn"><span>Start Free Trial</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <section class="testimonial-section style-2 padding-tb">
            <div class="container">
                <div class="section-header">
                    <span>OUR TESTIMONIALS</span>
                    <h2>Our Customer Always Happy With Our Services</h2>
                </div>
                <div class="section-wrapper">
                    <div class="testimonial-slider-two">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active">
                                    <img src="temp-assets/images/team/01.jpg" alt="testimonial" />
                                </li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1">
                                    <img src="temp-assets/images/team/02.jpg" alt="testimonial" />
                                </li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2">
                                    <img src="temp-assets/images/team/03.jpg" alt="testimonial" />
                                </li>
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3">
                                    <img src="temp-assets/images/team/04.jpg" alt="testimonial" />
                                </li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="testi-item">
                                        <div class="testi-inner">
                                            <div class="testi-thumb">
                                                <img src="temp-assets/images/team/01.jpg" alt="testimonial" />
                                            </div>
                                            <div class="testi-content">
                                                <p><img src="temp-assets/images/testimonial/quote/marketing/01.png" alt="testimonial" class="left-img" />Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Soiund Leadership Skills Creative Holstic Process Imprvents Proactively Streane  Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Souind Alternative Niche Markets Forwor Resource<img src="temp-assets/images/testimonial/quote/marketing/02.png" alt="testimonial" class="right-img" /></p>
                                                <h6>Somrat Islam  <span>(UI Designer)</span></h6>
                                                <div class="rating">
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testi-item">
                                        <div class="testi-inner">
                                            <div class="testi-thumb">
                                                <img src="temp-assets/images/team/02.jpg" alt="testimonial" />
                                            </div>
                                            <div class="testi-content">
                                                <p><img src="temp-assets/images/testimonial/quote/marketing/01.png" alt="testimonial" class="left-img" />Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Soiund Leadership Skills Creative Holstic Process Imprvents Proactively Streane  Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Souind Alternative Niche Markets Forwor Resource<img src="temp-assets/images/testimonial/quote/marketing/02.png" alt="testimonial" class="right-img" /></p>
                                                <h6>Zinat Zaara  <span>(Web Designer)</span></h6>
                                                <div class="rating">
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testi-item">
                                        <div class="testi-inner">
                                            <div class="testi-thumb">
                                                <img src="temp-assets/images/team/03.jpg" alt="testimonial" />
                                            </div>
                                            <div class="testi-content">
                                                <p><img src="temp-assets/images/testimonial/quote/marketing/01.png" alt="testimonial" class="left-img" />Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Soiund Leadership Skills Creative Holstic Process Imprvents Proactively Streane  Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Souind Alternative Niche Markets Forwor Resource<img src="temp-assets/images/testimonial/quote/marketing/02.png" alt="testimonial" class="right-img" /></p>
                                                <h6>Rajib Raj  <span>(Web Designer)</span></h6>
                                                <div class="rating">
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="testi-item">
                                        <div class="testi-inner">
                                            <div class="testi-thumb">
                                                <img src="temp-assets/images/team/04.jpg" alt="testimonial" />
                                            </div>
                                            <div class="testi-content">
                                                <p><img src="temp-assets/images/testimonial/quote/marketing/01.png" alt="testimonial" class="left-img" />Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Soiund Leadership Skills Creative Holstic Process Imprvents Proactively Streane  Extend Accurate Services  Long Term High Impact Experiences Interactiv Streamline Team Compelingly Simplify Solutions Before Techncal Souind Alternative Niche Markets Forwor Resource<img src="temp-assets/images/testimonial/quote/marketing/02.png" alt="testimonial" class="right-img" /></p>
                                                <h6>Umme Hafsa  <span>(UI Designer)</span></h6>
                                                <div class="rating">
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                    <i class="icofont-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

		<section class="blog-section marketing padding-tb">
            <div class="container">
                <div class="section-header">
                    <span>Blog</span>
                    <h2>Our Latest Blog And News</h2>
                </div>
                <div class="section-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="post-item">
                                <div class="post-item-inner">
                                    <div class="post-thumb">
                                        <a href="#"><img src="temp-assets/images/blog/01.jpg" alt="lab-blog" /></a>
                                    </div>
                                    <div class="post-content">
                                        <h4><a href="#">Business Ueporting Rouncil Them Could Plan...</a></h4>
                                        <div class="author-date">
                                            <a href="#" class="date"><i class="icofont-calendar"></i>July 12, 2021</a>
                                            <a href="#" class="admin"><i class="icofont-ui-user"></i>Somrat Islam</a>
                                        </div>
                                        <p>Pluoresntly customize pranci an plcentered  customer service anding strategic amerials Interacvely cordinate performe</p>
                                        <div class="post-footer">
                                            <a href="#" class="text-btn">Read More<i class="icofont-double-right"></i></a>
                                            <a href="#" class="comments"><i class="icofont-comment"></i><span>2</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="post-item">
                                <div class="post-item-inner">
                                    <div class="post-thumb">
                                        <a href="#"><img src="temp-assets/images/blog/02.jpg" alt="lab-blog" /></a>
                                    </div>
                                    <div class="post-content">
                                        <h4><a href="#">Financial Reporting Qouncil What Could More...</a></h4>
                                        <div class="author-date">
                                            <a href="#" class="date"><i class="icofont-calendar"></i>July 12, 2021</a>
                                            <a href="#" class="admin"><i class="icofont-ui-user"></i>Somrat Islam</a>
                                        </div>
                                        <p>Pluoresntly customize pranci an plcentered  customer service anding strategic amerials Interacvely cordinate performe</p>
                                        <div class="post-footer">
                                            <a href="#" class="text-btn">Read More<i class="icofont-double-right"></i></a>
                                            <a href="#" class="comments"><i class="icofont-comment"></i><span>2</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="post-item">
                                <div class="post-item-inner">
                                    <div class="post-thumb">
                                        <a href="#"><img src="temp-assets/images/blog/03.jpg" alt="lab-blog" /></a>
                                    </div>
                                    <div class="post-content">
                                        <h4><a href="#">Consulting Reporting Qounc Arei Could More...</a></h4>
                                        <div class="author-date">
                                            <a href="#" class="date"><i class="icofont-calendar"></i>July 12, 2021</a>
                                            <a href="#" class="admin"><i class="icofont-ui-user"></i>Somrat Islam</a>
                                        </div>
                                        <p>Pluoresntly customize pranci an plcentered  customer service anding strategic amerials Interacvely cordinate performe</p>
                                        <div class="post-footer">
                                            <a href="#" class="text-btn">Read More<i class="icofont-double-right"></i></a>
                                            <a href="#" class="comments"><i class="icofont-comment"></i><span>2</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

		
		<footer>
            <div class="footer-top style-2 maketing padding-tb">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="footer-item first-set">
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <div class="title">
                                            <h6>About SmratMarketing</h6>
                                        </div>
                                        <div class="content">
                                            <p>We believe in Simplify.</p>
                                            <h6>Headquarters:</h6>
                                            <p>795 Folsom Ave, Suite 600 San Francisco, CA 94107</p>
                                            <ul>
                                                <li>
                                                    <p><span>Phone:</span>(91) 8547 632521</p>
                                                </li>
                                                <li>
                                                    <p><span>Email:</span><a href="#">info@gmail.com</a></p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="footer-item">
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <div class="title">
                                            <h6>Theme Features</h6>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li><a href="#">Documentation</a></li>
                                                <li><a href="#">Feedback</a></li>
                                                <li><a href="#">Plugins</a></li>
                                                <li><a href="#">Support Forums</a></li>
                                                <li><a href="#">Themes</a></li>
                                                <li><a href="#">WordPress Blog</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="footer-item">
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <div class="title">
                                            <h6>Social Contact</h6>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li><a href="#"><i class="icofont-facebook"></i>Facebook</a></li>
                                                <li><a href="#"><i class="icofont-twitter"></i>Twitter</a></li>
                                                <li><a href="#"><i class="icofont-instagram"></i>Instagram</a></li>
                                                <li><a href="#"><i class="icofont-youtube"></i>YouTube</a></li>
                                                <li><a href="#"><i class="icofont-xing"></i>Xing</a></li>
                                                <li><a href="#"><i class="icofont-github"></i>Github</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <div class="footer-item">
                                <div class="footer-inner">
                                    <div class="footer-content">
                                        <div class="title">
                                            <h6>Our Support</h6>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li><a href="#">Help Center</a></li>
                                                <li><a href="#">Paid with Mollie</a></li>
                                                <li><a href="#">Status</a></li>
                                                <li><a href="#">Changelog</a></li>
                                                <li><a href="#">Contact Support</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom style-2">
                <div class="container">
                    <div class="section-wrapper">
                        <p>&copy; 2021 All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
		
		<a href="#" class="scrollToTop"><i class="icofont-swoosh-up"></i><span class="pluse_1"></span><span class="pluse_2"></span></a>

		
   </>
  )
}
